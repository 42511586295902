<template>
  <nav
    class="side-menu"
    :class="{ visible }"
    tabindex="0"
    ref="base"
    @blur="blur"
  >
    <div class="item" v-t="'label.app'" />
    <router-link
      :to="{ name: routes.CALCULATOR, params: { lang: locale } }"
      class="item"
      v-t="'menu.calculator'"
      tabindex="-1"
    />
    <router-link
      :to="{ name: routes.DASHBOARD_METRIC, params: { lang: locale } }"
      class="item"
      v-t="'menu.dashboard'"
      v-if="authorized && isAdmin"
      tabindex="-1"
    />
    <router-link
      :to="{
        name: routes.ITEMS_CATALOG,
        params: { lang: locale, category: 0 },
      }"
      class="item"
      v-t="'menu.items'"
      tabindex="-1"
    />
    <router-link
      :to="{ name: routes.SKILLS, params: { lang: locale } }"
      class="item"
      v-t="'menu.skills'"
      tabindex="-1"
    />
    <router-link
      :to="{ name: routes.DROP, params: { lang: locale } }"
      class="item"
      v-t="'menu.drop-history'"
      v-if="isDebug"
      tabindex="-1"
    />
    <a
      href="https://t.me/warspear_mind"
      class="item"
      tabindex="-1"
      v-text="'Mind of Warspear'"
      target="_blank"
    />
    <a
      href="https://discord.gg/fan-ws-ru"
      class="item"
      tabindex="-1"
      v-text="'Fan Warspear Online RU'"
      target="_blank"
    />
    <a
      href="https://www.buymeacoffee.com/symbx"
      class="item"
      tabindex="-1"
      v-text="'Buy me a coffee'"
      target="_blank"
    />
    <div class="group">
      <div
        class="item"
        v-for="lang in langs"
        :key="lang.code"
        @click="setLang(lang.code)"
        v-text="lang.name"
      />
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import { ROUTES } from "@/router";
import { RIGHT } from "@/api";
import { available, setLang } from "@/lang";

export default {
  name: "side-menu",
  data() {
    return {
      debug: parseInt(localStorage.getItem("debug") || 0) > 0,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  events: {
    change: {
      custom: true,
    },
  },
  model: {
    prop: "visible",
    event: "change",
  },
  computed: {
    ...mapState({
      authorized: (state) => state.token.length > 0,
      rights: (state) => state.rights,
    }),
    routes: () => ROUTES,
    isAdmin() {
      return this.rights.includes(RIGHT.UPLOAD_PKG);
    },
    isDebug() {
      return !!localStorage.getItem("debug");
    },
    locale() {
      return this.$route.params.lang;
    },
    langs() {
      return available;
    },
  },
  methods: {
    open() {
      this.$refs.base.focus();
    },
    blur(e) {
      if (
        e.relatedTarget &&
        e.relatedTarget.parentElement === this.$refs.base
      ) {
        e.relatedTarget.click();
      }
    },
    setLang(code) {
      setLang(code);
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          lang: code,
        },
      });
    },
  },
};
</script>

<style scoped>
.side-menu {
  background-image: url("../assets/bg.png");
  background-size: 112px;
  box-shadow: 0 0 2px #000;
  height: 100%;
  image-rendering: pixelated;
  position: fixed;
  top: 0;
  transition-duration: 0.45s;
  width: 280px;
  z-index: 9;
  border-right: 2px solid #e7cf67;
  left: -100%;
  transition-property: left;
  display: flex;
  flex-direction: column;
}
.side-menu.visible,
.side-menu:focus {
  left: 0;
}
.side-menu > .item {
  padding: 8px;
  color: #fff;
  height: 36px;
  line-height: 36px;
  text-shadow: -1px 0 1px #000, 0 -1px 1px #000, 1px 0 1px #000, 0 1px 1px #000;
}
.side-menu > a.item {
  cursor: pointer;
}
.side-menu > .item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
.side-menu > .item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}
.group {
  margin-top: auto;
  border-top: 1px solid #e7cf67;
}
.group > .item {
  color: #fff;
  text-decoration: underline;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
}
.group > .item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
.group > .item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
