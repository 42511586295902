import { render, staticRenderFns } from "./side-menu.vue?vue&type=template&id=2eeacc1c&scoped=true&"
import script from "./side-menu.vue?vue&type=script&lang=js&"
export * from "./side-menu.vue?vue&type=script&lang=js&"
import style0 from "./side-menu.vue?vue&type=style&index=0&id=2eeacc1c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eeacc1c",
  null
  
)

export default component.exports